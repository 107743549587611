body{
    margin: 0;
}
#Footer {
    width: 100%;
    height: auto;
}
#Footer > img{
    height: 100%;
    width: 100%;
    display: block;
}