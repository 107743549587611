#validationQualification{
    width: 100%;
    height: auto; 
}

#validationQualification > img{
    width: 100%;
    height: 100%;
}

#validationQualification > video {
    width: 100%;
    height: 100%;
}

.qualificationBottomIMG {
    height: auto;
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 1%;
    align-items: flex-start;
    padding-bottom: 8%;
}

.qualificationBottomIMG > img {
    width: 25%;
}

.mQualificationBottomIMG {
    height: auto;
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 1%;
    align-items:flex-start;
    padding-bottom: 8%;
}

.mQualificationBottomIMG > img {
    width: 70%;
}

@media screen and (max-width: 900px) {
    .mQualificationBottomIMG > img {
        margin-top: 5%;
        width: 60%;
        height: 10%;
    }
    .mQualificationBottomIMG {
        height: auto;
        width: 100%;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10%;
        align-items:center;
        padding-bottom: 10%;
    }
}