#validationPage {
    width: 100%;
    height: auto;
}
/*header*/
#contactHeader {
    width: 100%;
    height: 15vh;
    z-index: 1;
}

/*baner*/
#contactbanner {
    width: 100%;
    height: auto; 
}
#contactBody{
    width: 100%;
    height: auto;
}


#validationFooter {
    width: 100%;
    position: relative;
    height: auto;
    z-index: 1;
}


@media screen and (max-width: 900px) {
    #contactHeader {
        height: 8vh;
    }
}