#body {
    width: 100%;
    height: auto;
}

#body>img {
    width: 100%;
    height: auto;
}

.contactNumber {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8%;
}

.contactNumber>div {
    width: 30%;
    position: relative;
}

.contactNumber>div>img {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.contactNumber>div>p {
    display: block;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    margin: 0;
    font-size: 2.6vh;
    font-family: Pretendard;
    user-select: all;
}

@media screen and (max-width: 1100px) {
    .contactNumber>div>p {
        font-size: 0.8vh;
    }
}

.mContactNumber {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8%;
}

.mContactNumber > div {
    width: 85%;
    position: relative;
}

.mContactNumber > div > img {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.mContactNumber > div > p {
    display: block;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    margin: 0;
    font-size: 2.6vh;
    font-family: Pretendard;
    user-select: all;
}

.contactNumber > div :hover{
    cursor: pointer;
}