#banner {
    height: 100%;
    width: auto;
}

#banner> img{
    width: 100%;
    height: 100%;
    display: block;
}


