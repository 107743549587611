#headOfficeMap{
    width: 100%;
    height: 100%;
}

#map{
    width: 100%;
    height: 100%;
}
#factory{
    width: 100%;
    height: 100%;
}