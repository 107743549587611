#mainPage {
    width: 100%;
    height: auto;
}

#mainHeader {
    width: 100%;
    height: 15%;
    position: absolute ;
    z-index: 1;
}

#mainBanner {
    width: 100%;
    height: auto;
}

#mainText {
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
}

#mainFooter {
    width: 100%;
    height: auto;
}