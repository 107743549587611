.automationAfterService {
    width: 100%;
    height: auto;
}

.afterService1 {
    width: 100%;
    height: 100%;
   
}
.afterService1>img{
    width: 100%;
    height: auto;
}


.hyperLink {
    text-align: center;
    margin-bottom: 10vb;
}
.hyperLink > img{
    width: 40%;
    height: auto;
}

.hoverLink {
    text-align: center;
    margin-bottom: 10vb;
    cursor: pointer;
}
.hoverLink > img {
    width: 40%;
    height: auto;
}


.additionalIMG {
    text-align: center;
}
.additionalIMG >img{
    width: 100%;
    height: auto;

}


.mHyperLink {
    text-align: center;
    margin-bottom: 1vb;
}

.mHyperLink > img {
    width: 55%;
    height: auto;
}