#companyHeaderContiner {
    display: flex;
    width: 80%;
    height: 100%;
    margin: 0 10%;
    align-items: center;
    justify-content: space-between;
}
#companyHeaderLogo{
    display: flex;
    align-items: center;
}
#companyHeaderLogo> img{
    width: 7vw;
    cursor: pointer;
}

#companyHeaderMenu {
    width: 30%;
    display: flex;
    font-size: 1vw;
    justify-content: space-between;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}


@media screen and (max-width: 900px) {

	#companyHeaderLogo> img{
        width: 10vw;
    }
    
    #companyHeaderMenu {
        width: 65%;
        display: flex;
        font-size: 2.2vw;
    }
}