#companyWayBackground{
    position: relative;
    width: 100%;
    height: auto;
    
}
#companyWayBackground> img {
    position: relative;
    width: 100%;
    height: 100%;
}

/* map */

#companyWay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

#companyMaps{
    position: absolute;
    margin-left: 10%;
    width: 80%;
    height: 80%;
    top: 15%;
    display: flex;
    flex-direction: column;
}

#companyMaps > * {
    width: 100%;
    height: 50%;

}
#companyMaps>div>h4{
    font-family: pretendard;
    font-size: 1.2vw;
}


#individual_map{
    width: 100%;
    height: 70%;

}
#companyMaps>div>p{
    font-family: pretendard;
    text-align: end;
    font-size: 1vw;
}

@media screen and (max-width: 900px) {
    #companyMaps>div>h4 {
        font-size: 3vw;
    }
    #companyMaps>div>p {
        font-size: 2.2vw;
    }
}