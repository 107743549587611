#automationPage {
    width: 100%;
    height: auto;
}

/*header*/
#automationHeader {
    width: 100%;
    height: 15vh;
    z-index: 1;
}
/*baner*/
#automationBanner {
    width: 100%;
    height: auto; 
}


/*select img*/
/* 글 정하기  */
#automationOverviewSelect{
    width: 100%;
    height: 10vh;
    z-index: 2;
}

/* 안 이미지 조절 */
#autoimationview {
    width: 100%;
    height: auto;
}

#scadaSystem{
    width: 100%;
    height: auto;
}


.active{
    display: block;
	animation: formopen;
	animation-duration: 1s;
    
}
.image{
    opacity: 0;
    display: none;
}

@keyframes formopen {
    0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
  }


/* footer */
#automationFooter {
    width: 100%;
    position: relative;
    height: auto;
    z-index: 1;
}

@media screen and (max-width: 900px) {
    #automationHeader {
        height: 8vh;
    }
}