.automationOverviewSelects{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}
.aselects{
    width: 54%;
    height: 70%;
    margin: auto;
    margin-bottom: 0;
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: center;
}

.aselects>p{
    text-align: center;
    width: 33%;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-family: pretendard;
    font-size: 1vw;
}

.aselecteBar{
    position: relative;
    width: 54%;
    height: 5%;
    background-color: #F1F2F9;
    z-index: -1;
}
.automationOverviewbar{
    width: 33%;
    height: 100%;
    background: linear-gradient(to right, rgba(0,243,199,0.4), rgba(66,200,255,0.4), rgba(119,55,255,0.4));
    transition: transform 0.5s ease-in-out, width 0.5s ease-in-out, left 0.5s ease-in-out, border-radius 0.5s ease-in-out; 
    position: absolute;
    left: 0%;
    top: 0%;
}

@media screen and (max-width: 900px) {
    .aselects > p{
        font-size: 2.0vw;
    }
}