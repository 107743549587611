.validationCycleDevelopment {
    width: 100%;
    height: auto;
}

.validationCycleDevelopment > img {
    width: 100%;
    height: 100%;
}

.MVHPIMages {
    height: auto;
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 5%;
    align-items: flex-start;
    padding-top: 2%;
    padding-bottom: 7%;
}

.MVHPIMages > img {
    width: 80%;
    display: block;
}

.VHPImages {
    height: auto;
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 5%;
    align-items: flex-start;
    padding-top: 2%;
    padding-bottom: 7%;
}



.VHPImages > img {
    width: 31%;
    display: block;
}

.toggle {
    padding-left: 17%;
    padding-bottom: 5%;
}

.toggle > img {
    display: block;
    width: 100%;
    height: 100%;
}


.disable {
    width: 80%;
    height: auto;
}

.disable > img:nth-child(1){
    
    width: 80%;
    height: 100%;

}
.disable + img {
    display: none;
}
.disable + .toggleVideo {
    display: none;
}
.acctive{
    width: 80%;
    height: auto;
}
.acctive > img:nth-child(1){
    width: 80%;
    height: 100%;
}
.acctive + .toggleVideo {
    display: block;
    animation: formopen;
	animation-duration: 1.5s;
}

.toggleVideo{
    position: relative;
}
.s11 {
    width: 100%;
    height: 100%;
}
.s12 {
    width: 25%;
}
.toggleVideo > video{
    position: absolute;
    border-radius: 0.8rem;
    width: 30.5%;
}


.MToggle > img {
    display: block;
    width: 100%;
    height: 100%;
}

.MDisable > img:nth-child(1) {
    width: 100%;
    height: 100%;
}
.MDisable + img {
    display: none;
}
.MDisable + .mToggleVideo {
    display: none;
}

.MAcctive {
    width: 100%;
    height: auto;
}
.MAcctive > img:nth-child(1) {
    width: 100%;
    height: 100%;
}
.MAcctive + img {
    display: block;
    animation: formopen;
	animation-duration: 1.5s;
}
.mToggleVideo {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mToggleVideo > img {
    width: 100%;
    height: 100%;
}
.mToggleVideo > video {
    width: 70%;
    border-radius: 0.8rem;

}




@keyframes formopen {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

  @media screen and (max-width: 900px) {
    .VHPImages {
        height: auto;
        width: 100%;
    
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 5%;
        align-items: center;
        padding-top: 0%;
        padding-bottom: 0%;
    }

    .VHPImages > img {
        width: 90%;
        display: block;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    
}