#mainPageHeader {
    width: 80%;
    height: 100%;
    display: flex;
    margin: 0 10%;
    align-items: center;
    justify-content: space-between;
}

#mainPageHeaderLogo {
}
#mainPageHeaderLogo> img{
    width: 7vw;
}

#mainPageHeaderMenu {
    width: 30%;
    display: flex;
    font-size: 1vw;
    justify-content: space-between;
}

@media screen and (max-width: 900px) {

	#mainPageHeaderLogo> img{
        width: 10vw;
    }
    
    #mainPageHeaderMenu {
        width: 65%;
        display: flex;
        font-size: 2.2vw;
    }
}