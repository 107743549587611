#companyPage {
    width: 100%;
    height: 100vh;
}

/*header*/
#companyHeader {
    width: 100%;
    height: 15vh;
    z-index: 1;
}
/*baner*/
#companyBanner {
    width: 100%;
    height: auto; 
}


/*select img*/
#companyOverviewSelect{
    width: 100%;
    height: 10vh;
    z-index: 2;
}

/* 안 이미지 조절 */
#companyOverview {
    width: 100%;
    height: auto;
}



#companyOverview> img {
    width: 100%;
    height: auto;
}
.active{
    display: block;
	animation: formopen;
	animation-duration: 1s;
    
}
.image{
    opacity: 0;
    display: none;
}

@keyframes formopen {
    0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
  }

/* 오시는 길 */
#companyWaytoBackground{
    width: 100%;
    height: auto;
}


/* footer */

#companyFooter {
    width: 100%;
    position: relative;
    height: auto;
    z-index: 1;
}

@media screen and (max-width: 900px) {
    #companyHeader {
        height: 8vh;
    }
}