.mainBanner {
    height: 100vh;
    width: 100%;
    position: relative;
    background-image: url("../mainPageIMG/mainbackground.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainText {
    width: 40%;
    z-index: 1;
}

